<template>
  <a-card :bodyStyle="{padding:0}">
    <template #title>
      <a-alert message="注意：该列表包含未归档数据，请以最终归档数据为准，灰色为未归档" type="warning" show-icon />
      <a-button @click="exportDetailExcel">导出明细</a-button>
    </template>
    <a-table :dataSource="dataSource" :rowKey="record => record.id" :loading="loading" @change="tableChage" :pagination="false" bordered size="small" :scroll="{x: true}" :rowClassName="rowClass">
      <a-table-column width="300" title="部门" data-index="orgName" :filters="orgs" :onFilter="(value,record) => record.orgId === value || record.id === 'totalRow'" />
      <a-table-column-group>
        <template #title>主营业务</template>
        <a-table-column width="100" title="接车数量" data-index="carNum" />
        <a-table-column width="100" title="营业收入" data-index="income" />
        <a-table-column width="100" title="开票收入" data-index="invoiceIncome" />
        <a-table-column width="100" title="油卡金额" data-index="oilAmt" />
        <a-table-column-group>
          <template #title>营业成本</template>
          <a-table-column width="100" title="干线运输" data-index="transFee" />
          <a-table-column width="100" title="取送车" data-index="pickoutFee" />
          <a-table-column width="100" title="附加费" data-index="additionFee" />
          <a-table-column width="100" title="保险" data-index="insuranceFee" />
          <a-table-column width="100" title="税费" data-index="rateFee" />
          <a-table-column width="100" title="应调整税费" data-index="finalRateFee" />
        </a-table-column-group>
        <a-table-column width="100" title="毛利" data-index="carProfit" />

        <a-table-column-group>
          <template #title>其他费用</template>
          <a-table-column width="100" title="日常开销" data-index="dailyExpenses" />
          <a-table-column width="100" title="财务工资" data-index="financeSalary" />
          <a-table-column width="100" title="业务工资" data-index="businessSalary" />
          <a-table-column width="100" title="网络推广" data-index="netPromotion" />
          <a-table-column width="100" title="客服提成" data-index="serviceSalaryCommission" />
          <a-table-column width="100" title="社保公积金" data-index="socialFund" />
          <a-table-column width="100" title="利润调整" data-index="profitAdjust" />
        </a-table-column-group>
        <a-table-column width="100" title="主营利润" data-index="mainBusinessProfit" />
      </a-table-column-group>
      <a-table-column-group>
        <template #title>非主营业务</template>
        <a-table-column width="100" title="车队返利" data-index="fleetRebate" />
        <a-table-column width="100" title="代管费" data-index="escrowFee" />
        <a-table-column width="100" title="业务员提成" data-index="salesmanCommission" />
      </a-table-column-group>
      <a-table-column width="100" title="净利润合计" data-index="netProfitTotal" />
      <a-table-column width="100" title="应上交利润" data-index="profitPayable" />
      <a-table-column width="100" title="已结算利润" data-index="profitSettled" />
      <a-table-column width="100" title="利润调整" data-index="profitShareAdjust" />
      <a-table-column width="100" title="最终利润" data-index="netProfitFinalTotal" />
      <template #footer></template>
    </a-table>
  </a-card>
</template>
<script>
import { transProfitArchiveList, exportProfitArchive, exportProfitDetailArchive, exportEscrowArchive } from '@/api/trans/finance/profit'
import { reactive, toRefs } from 'vue'
import { watch } from '@vue/runtime-core'
import { message } from 'ant-design-vue'
export default {
  props: { query: { type: Object, default: () => { return { year: '', month: '' } } } },
  setup (props) {
    const state = reactive({
      loading: false,
      orgs: [],
      dataSource: []
    })

    watch(() => props.query, (query) => {
      transProfitArchiveList(query).then(res => {
        if (res.code === 10000) {
          state.dataSource = res.data
          state.orgs = res.data.map(val => { return { text: val.orgName, value: val.orgId } })
          state.dataSource.push(tableTotal(res.data))
        }
      })
    })

    const tableTotal = (data = []) => {
      const totalRow = {
        carNum: 0,
        income: 0,
        transFee: 0,
        pickFee: 0,
        takeFee: 0,
        insuranceFee: 0,
        invoiceIncome: 0,
        oilAmt: 0,
        rateFee: 0,
        pickoutFee: 0,
        additionFee: 0,
        finalRateFee: 0,
        carProfit: 0,
        dailyExpenses: 0,
        financeSalary: 0,
        businessSalary: 0,
        netPromotion: 0,
        serviceSalaryCommission: 0,
        socialFund: 0,
        profitAdjust: 0,
        mainBusinessProfit: 0,
        fleetRebate: 0,
        escrowFee: 0,
        salesmanCommission: 0,
        netProfitTotal: 0,
        profitPayable: 0,
        profitSettled: 0,
        profitShareAdjust: 0,
        netProfitFinalTotal: 0
      }
      data.forEach(item => {
        for (const key in totalRow) {
          totalRow[key] += Number(item[key])
        }
      })
      totalRow.id = 'totalRow'
      totalRow.orgName = '合计'
      return totalRow
    }

    const tableChage = (pagination, filters) => {
      const filterData = state.dataSource.filter(item => filters.orgName.includes(item.orgId))
      if (filterData.length) {
        state.dataSource.splice(state.dataSource.length - 1, 1, tableTotal(filterData))
      } else {
        state.dataSource.splice(state.dataSource.length - 1, 1, tableTotal(state.dataSource.slice(0, -1)))
      }
    }

    const exportExcel = () => {
      exportProfitArchive(props.query, '利润报表').then(res => {
        if (res === 10000) {
          message('导出成功！')
        }
      })
    }

    const exportEscrowExcel = () => {
      exportEscrowArchive(props.query, '代管费报表').then(res => {
        if (res === 10000) {
          message('导出成功！')
        }
      })
    }

    const exportDetailExcel = () => {
      exportProfitDetailArchive(props.query, '利润报表').then(res => {
        if (res === 10000) {
          message('导出成功！')
        }
      })
    }

    const rowClass = (record, index) => {
      let rowClassName = ''
      if (index % 2 === 1) {
        rowClassName = 'table-striped'
      }
      if (record.status && !record.status.value) {
        rowClassName += ' not-archive'
      }
      return rowClassName
    }

    return {
      ...toRefs(state),
      rowClass,
      tableChage,
      exportExcel,
      exportEscrowExcel,
      exportDetailExcel
    }
  }
}
</script>
<style lang="less" scoped>
.ant-table td {
  white-space: nowrap;
}
.not-archive {
  color: #999;
}
</style>
