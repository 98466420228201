<template>
  <a-card>

    <a-form ref="formRef" :model="form" scrollToFirstError hideRequiredMark>
      <template v-if="form.status && form.status.value">
        <img class="archiveTag" src='@/assets/images/archived.png' />
      </template>
      <template v-else>
        <a-alert message="归档之后的数据无法再次修改，请谨慎操作" type="warning" show-icon />
        <img class="archiveTag" src='@/assets/images/notArchive.png' />
      </template>
      <table class="table">
        <thead>
          <tr>
            <th class="table-title" colspan="24">{{form.year}}年{{form.month}}月利润归档（{{form.orgName}}）</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="label-td center-td" rowspan="9" colspan="2" width="10%">
              主营业务
            </td>
            <td class="label-td" colspan="4" width="20%">
              接车数量
              <span class="label-desc">（辆）<a-button  @click="exportDetailExcel">导出明细</a-button></span>
            </td>
            <td class="left-td value-td" colspan="6" width="25%">
              {{form.carNum}}
            </td>
            <td class="label-td" colspan="4" width="15%">
              营业收入
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td value-td" colspan="8" width="30%">
              {{form.income}}
            </td>
          </tr>
          <tr>
            <td class="label-td" colspan="4" width="20%">
              开票收入
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td value-td" colspan="6" width="25%">
              {{form.invoiceIncome}}
            </td>
            <td class="label-td" colspan="4" width="15%">
              油卡金额
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td value-td" colspan="8" width="30%">
              {{form.oilAmt}}
            </td>
          </tr>
          <tr>
            <td class="label-td" rowspan="3" colspan="2" width="10%">
              营业成本
            </td>
            <td class="label-td" colspan="2" width="10%">
              发车
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td value-td" colspan="4" width="15%">
              {{form.transFee}}
            </td>
            <td class="label-td" colspan="2" width="10%">
              取送车
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td value-td" colspan="5" width="15%">
              {{form.pickoutFee}}
            </td>
            <td class="label-td" colspan="2" width="10%">
              保险
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td value-td" colspan="5" width="20%">
              {{form.insuranceFee}}
            </td>
          </tr>

          <tr>
            <td class="label-td" colspan="2" width="10%">
              税费
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td value-td" colspan="4" width="15%">
              {{form.rateFee}}
            </td>
            <td class="label-td" colspan="2" width="10%">
              附加费
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td value-td" colspan="5" width="15%">
              {{form.additionFee}}
            </td>
            <td class="label-td" colspan="2" width="15%">
              应调税费
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td" colspan="8" width="20%">
              <template v-if="form.status && form.status.value">
                {{form.finalRateFee}}
              </template>
              <template v-else>
                <a-form-item :wrapperCol="{flex:'auto'}" name="finalRateFee">
                  <a-tooltip :trigger="['focus']" placement="topLeft" overlay-class-name="numeric-input">
                    <template #title>
                      <span class="numeric-input-title">
                        {{formatNumber(form.finalRateFee)}}
                      </span>
                    </template>
                    <a-input v-model:value="form.finalRateFee" placeholder="请填写金额" type="number" autocomplete="off" allowClear />
                  </a-tooltip>
                </a-form-item>
              </template>
            </td>
          </tr>

          <tr>
            <td class="label-td" colspan="2" width="10%">
              毛利
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td value-td" colspan="18" width="70%">
              <a-typography-text type="danger"> ￥ {{form.carProfit}}</a-typography-text>
              大写： <a-typography-text type="danger"> {{amountUppercase(form.carProfit)}} </a-typography-text>
            </td>
          </tr>

          <tr>
            <td class="label-td" rowspan="3" colspan="2" width="10%">
              其他费用
            </td>
            <td class="label-td" colspan="2" width="10%">
              日常开销
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td" colspan="4" width="15%">
              <template v-if="form.status && form.status.value">
                {{form.dailyExpenses}}
              </template>
              <template v-else>
                <a-form-item :wrapperCol="{flex:'auto'}" name="dailyExpenses">
                  <a-tooltip :trigger="['focus']" placement="topLeft" overlay-class-name="numeric-input">
                    <template #title>
                      <span class="numeric-input-title">
                        {{formatNumber(form.dailyExpenses)}}
                      </span>
                    </template>
                    <a-input v-model:value="form.dailyExpenses" placeholder="请填写金额" type="number" autocomplete="off" allowClear />
                  </a-tooltip>
                </a-form-item>
              </template>
            </td>
            <td class="label-td" colspan="2" width="10%">
              网络推广
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td" colspan="5" width="15%">
              <template v-if="form.status && form.status.value">
                {{form.netPromotion}}
              </template>
              <template v-else>
                <a-form-item :wrapperCol="{flex:'auto'}" name="netPromotion">
                  <a-tooltip :trigger="['focus']" placement="topLeft" overlay-class-name="numeric-input">
                    <template #title>
                      <span class="numeric-input-title">
                        {{formatNumber(form.netPromotion)}}
                      </span>
                    </template>
                    <a-input v-model:value="form.netPromotion" placeholder="请填写金额" type="number" autocomplete="off" allowClear />
                  </a-tooltip>
                </a-form-item>
              </template>
            </td>
            <td class="label-td" colspan="2" width="10%">
              社保公积金
            </td>
            <td class="left-td" colspan="5" width="20%">
              <template v-if="form.status && form.status.value">
                {{form.socialFund}}
              </template>
              <template v-else>
                <a-form-item :wrapperCol="{flex:'auto'}" name="socialFund">
                  <a-tooltip :trigger="['focus']" placement="topLeft" overlay-class-name="numeric-input">
                    <template #title>
                      <span class="numeric-input-title">
                        {{formatNumber(form.socialFund)}}
                      </span>
                    </template>
                    <a-input v-model:value="form.socialFund" placeholder="请填写金额" type="number" autocomplete="off" allowClear />
                  </a-tooltip>
                </a-form-item>
              </template>
            </td>
          </tr>

          <tr>
            <td class="label-td" colspan="2" width="10%">
              财务工资
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td" colspan="4" width="15%">
              <template v-if="form.status && form.status.value">
                {{form.financeSalary}}
              </template>
              <template v-else>
                <a-form-item :wrapperCol="{flex:'auto'}" name="financeSalary">
                  <a-tooltip :trigger="['focus']" placement="topLeft" overlay-class-name="numeric-input">
                    <template #title>
                      <span class="numeric-input-title">
                        {{formatNumber(form.financeSalary)}}
                      </span>
                    </template>
                    <a-input v-model:value="form.financeSalary" placeholder="请填写金额" type="number" autocomplete="off" allowClear />
                  </a-tooltip>
                </a-form-item>
              </template>
            </td>
            <td class="label-td" colspan="2" width="10%">
              业务工资
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td" colspan="5" width="15%">
              <template v-if="form.status && form.status.value">
                {{form.businessSalary}}
              </template>
              <template v-else>
                <a-form-item :wrapperCol="{flex:'auto'}" name="businessSalary">
                  <a-tooltip :trigger="['focus']" placement="topLeft" overlay-class-name="numeric-input">
                    <template #title>
                      <span class="numeric-input-title">
                        {{formatNumber(form.businessSalary)}}
                      </span>
                    </template>
                    <a-input v-model:value="form.businessSalary" placeholder="请填写金额" type="number" autocomplete="off" allowClear />
                  </a-tooltip>
                </a-form-item>
              </template>
            </td>
            <td class="label-td" colspan="2" width="10%">
              客服提成
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td" colspan="5" width="20%">
              <template v-if="form.status && form.status.value">
                {{form.serviceSalaryCommission}}
              </template>
              <template v-else>
                <a-form-item :wrapperCol="{flex:'auto'}" name="serviceSalaryCommission">
                  <a-tooltip :trigger="['focus']" placement="topLeft" overlay-class-name="numeric-input">
                    <template #title>
                      <span class="numeric-input-title">
                        {{formatNumber(form.serviceSalaryCommission)}}
                      </span>
                    </template>
                    <a-input v-model:value="form.serviceSalaryCommission" placeholder="请填写金额" type="number" autocomplete="off" allowClear />
                  </a-tooltip>
                </a-form-item>
              </template>
            </td>
          </tr>

          <tr>
            <td class="label-td" colspan="2" width="10%">
              利润调整
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td" colspan="18" width="70%">
              <template v-if="form.status && form.status.value">
                {{form.profitAdjust}}
              </template>
              <template v-else>
                <a-form-item :wrapperCol="{flex:'auto'}" name="profitAdjust">
                  <a-tooltip :trigger="['focus']" placement="topLeft" overlay-class-name="numeric-input">
                    <template #title>
                      <span class="numeric-input-title">
                        {{formatNumber(form.profitAdjust)}}
                      </span>
                    </template>
                    <a-input v-model:value="form.profitAdjust" placeholder="请填写金额" type="number" autocomplete="off" allowClear />
                  </a-tooltip>
                </a-form-item>
              </template>
            </td>
          </tr>
          <tr>
            <td class="label-td" colspan="2">
              利润
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td value-td" colspan="20">
              <a-typography-text type="danger"> ￥ {{form.mainBusinessProfit}}</a-typography-text>
              大写： <a-typography-text type="danger"> {{amountUppercase(form.mainBusinessProfit)}} </a-typography-text>
            </td>
          </tr>

          <tr>
            <td class="label-td" colspan="2">
              营业外收支
            </td>
            <td class="label-td" colspan="2">
              车队返利
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td" colspan="6">
              <template v-if="form.status && form.status.value">
                {{form.fleetRebate}}
              </template>
              <template v-else>
                <a-form-item :wrapperCol="{flex:'auto'}" name="fleetRebate">
                  <a-tooltip :trigger="['focus']" placement="topLeft" overlay-class-name="numeric-input">
                    <template #title>
                      <span class="numeric-input-title">
                        {{formatNumber(form.fleetRebate)}}
                      </span>
                    </template>
                    <a-input v-model:value="form.fleetRebate" placeholder="请填写金额" type="number" autocomplete="off" allowClear />
                  </a-tooltip>
                </a-form-item>
              </template>
            </td>
            <td class="label-td" colspan="2">
              代管费
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td" colspan="5">
              <template v-if="form.status && form.status.value">
                {{form.escrowFee}}
              </template>
              <template v-else>
                <a-form-item :wrapperCol="{flex:'auto'}" name="escrowFee">
                  <a-tooltip :trigger="['focus']" placement="topLeft" overlay-class-name="numeric-input">
                    <template #title>
                      <span class="numeric-input-title">
                        {{formatNumber(form.escrowFee)}}
                      </span>
                    </template>
                    <a-input v-model:value="form.escrowFee" placeholder="请填写金额" type="number" autocomplete="off" allowClear />
                  </a-tooltip>
                </a-form-item>
              </template>
            </td>
            <td class="label-td" colspan="2">
              业务提成
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td" colspan="5">
              <template v-if="form.status && form.status.value">
                {{form.salesmanCommission}}
              </template>
              <template v-else>
                <a-form-item :wrapperCol="{flex:'auto'}" name="salesmanCommission">
                  <a-tooltip :trigger="['focus']" placement="topLeft" overlay-class-name="numeric-input">
                    <template #title>
                      <span class="numeric-input-title">
                        {{formatNumber(form.salesmanCommission)}}
                      </span>
                    </template>
                    <a-input v-model:value="form.salesmanCommission" placeholder="请填写金额" type="number" autocomplete="off" allowClear />
                  </a-tooltip>
                </a-form-item>
              </template>
            </td>
          </tr>
          <tr>
            <td class="label-td" colspan="4">
              净利润
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td value-td" colspan="20">
              <a-typography-text type="danger"> ￥ {{form.netProfitTotal}}</a-typography-text>
              大写： <a-typography-text type="danger"> {{amountUppercase(form.netProfitTotal)}} </a-typography-text>
            </td>
          </tr>

          <tr>
            <td class="label-td" colspan="4">
              应上交利润
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td" colspan="8">
              <template v-if="form.status && form.status.value">
                {{form.profitPayable}}
              </template>
              <template v-else>
                <a-form-item :wrapperCol="{flex:'auto'}" name="profitPayable">
                  <a-tooltip :trigger="['focus']" placement="topLeft" overlay-class-name="numeric-input">
                    <template #title>
                      <span class="numeric-input-title">
                        {{formatNumber(form.profitPayable)}}
                      </span>
                    </template>
                    <a-input v-model:value="form.profitPayable" placeholder="请填写金额" type="number" autocomplete="off" allowClear />
                  </a-tooltip>
                </a-form-item>
              </template>
            </td>
            <td class="label-td" colspan="4">
              已结算利润
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td" colspan="8">
              <template v-if="form.status && form.status.value">
                {{form.profitSettled}}
              </template>
              <template v-else>
                <a-form-item :wrapperCol="{flex:'auto'}" name="profitSettled">
                  <a-tooltip :trigger="['focus']" placement="topLeft" overlay-class-name="numeric-input">
                    <template #title>
                      <span class="numeric-input-title">
                        {{formatNumber(form.profitSettled)}}
                      </span>
                    </template>
                    <a-input v-model:value="form.profitSettled" placeholder="请填写金额" type="number" autocomplete="off" allowClear />
                  </a-tooltip>
                </a-form-item>
              </template>
            </td>
          </tr>
          <tr>
            <td class="label-td" colspan="4">
              利润调整金额
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td" colspan="20">
              <template v-if="form.status && form.status.value">
                {{form.profitShareAdjust}}
              </template>
              <template v-else>
                <a-form-item :wrapperCol="{flex:'auto'}" name="profitShareAdjust">
                  <a-tooltip :trigger="['focus']" placement="topLeft" overlay-class-name="numeric-input">
                    <template #title>
                      <span class="numeric-input-title">
                        {{formatNumber(form.profitShareAdjust)}}
                      </span>
                    </template>
                    <a-input v-model:value="form.profitShareAdjust" placeholder="请填写金额" type="number" autocomplete="off" allowClear />
                  </a-tooltip>
                </a-form-item>
              </template>
            </td>
          </tr>
          <tr>
            <td class="label-td" colspan="4">
              调整后净利润
              <span class="label-desc">（元）</span>
            </td>
            <td class="left-td value-td" colspan="20">
              <a-typography-text type="danger"> ￥ {{form.netProfitFinalTotal}}</a-typography-text>
              大写： <a-typography-text type="danger"> {{amountUppercase(form.netProfitFinalTotal)}} </a-typography-text>
            </td>
          </tr>

        </tbody>
      </table>
      <div class="save-button" v-if="form.status && !form.status.value">
        <a-space>
          <a-button type="primary" :loading="subLoading" @click="save">保存</a-button>
          <a-button type="primary" :loading="subLoading" @click="doArchive" danger>归档</a-button>
        </a-space>
      </div>
    </a-form>
  </a-card>
</template>
<script>
import {
  transProfitArchiveDetail,
  saveProfitArchive,
  archiveProfitArchive,
  exportProfitDetailArchive
} from '@/api/trans/finance/profit'
import { reactive, toRefs } from 'vue'
import { amountUppercase, formatNumber } from '@/utils/util'
import { watch } from '@vue/runtime-core'
import { message } from 'ant-design-vue'
export default {
  props: { archiveId: { type: String } },
  setup (props) {
    const state = reactive({
      subLoading: false,
      tag: Math.round(Math.random()),
      form: {
        id: undefined,
        orgId: '',
        orgName: '',
        month: '',
        carNum: 0,
        income: 0.00,
        transFee: 0.00,
        pickFee: 0.00,
        takeFee: 0.00,
        insuranceFee: 0.00,
        invoiceIncome: 0.00,
        oilAmt: 0.00,
        rateFee: 0.00,
        pickoutFee: 0.00,
        finalRateFee: '',
        couponAmt: 0.00,
        carProfit: 0,
        dailyExpenses: '',
        financeSalary: '',
        businessSalary: '',
        netPromotion: '',
        serviceSalaryCommission: '',
        socialFund: '',
        profitAdjust: '',
        mainBusinessProfit: 0,

        fleetRebate: '',
        escrowFee: '',
        salesmanCommission: '',
        netProfitTotal: 0,

        profitPayable: '',
        profitSettled: '',
        profitShareAdjust: '',
        netProfitFinalTotal: 0
      }
    })

    watch(() => props.archiveId, (archiveId) => {
      if (archiveId) {
        transProfitArchiveDetail(archiveId).then(res => {
          if (res.code === 10000) {
            state.form = { ...res.data }
          }
        })
      }
    })

    const exportDetailExcel = () => {
      exportProfitDetailArchive({
        officeOrgId: state.form.orgId,
        year: state.form.year,
        month: state.form.month
      }, '利润报表').then(res => {
        if (res === 10000) {
          message('导出成功！')
        }
      })
    }

    watch(() => [state.form.income, state.form.transFee, state.form.pickFee, state.form.takeFee, state.form.insuranceFee, state.form.rateFee, state.form.additionFee, state.form.finalRateFee,state.form.couponAmt], ([income, transFee, pickFee, takeFee, insuranceFee, rateFee, additionFee, finalRateFee, couponAmt]) => {
      // console.log(income, transFee, pickFee, takeFee, insuranceFee, rateFee, finalRateFee)
      const carProfit = Number(income) - Number(transFee) - Number(pickFee) - Number(takeFee) - Number(insuranceFee) - Number(rateFee) - Number(additionFee) -Number(couponAmt) + Number(finalRateFee)
      state.form.carProfit = carProfit.toFixed(2)
    })

    watch(() => [state.form.carProfit, state.form.dailyExpenses, state.form.financeSalary, state.form.businessSalary, state.form.netPromotion, state.form.serviceSalaryCommission, state.form.socialFund, state.form.profitAdjust], ([carProfit, dailyExpenses, financeSalary, businessSalary, netPromotion, serviceSalaryCommission, socialFund, profitAdjust]) => {
      // console.log(carProfit, dailyExpenses, financeSalary, businessSalary, netPromotion, serviceSalaryCommission, socialFund, profitAdjust)
      const mainBusinessProfit = Number(carProfit) - Number(dailyExpenses) - Number(financeSalary) - Number(businessSalary) - Number(netPromotion) - Number(serviceSalaryCommission) - Number(socialFund) + Number(profitAdjust)
      state.form.mainBusinessProfit = mainBusinessProfit.toFixed(2)
    })

    watch(() => [state.form.mainBusinessProfit, state.form.fleetRebate, state.form.escrowFee, state.form.salesmanCommission], ([mainBusinessProfit, fleetRebate, escrowFee, salesmanCommission]) => {
      // console.log(mainBusinessProfit, fleetRebate, escrowFee, salesmanCommission)
      const netProfitTotal = Number(mainBusinessProfit) + Number(fleetRebate) + Number(escrowFee) + Number(salesmanCommission)
      state.form.netProfitTotal = netProfitTotal.toFixed(2)
    })

    watch(() => [state.form.netProfitTotal, state.form.profitShareAdjust], ([netProfitTotal, profitShareAdjust]) => {
      // console.log(netProfitTotal, profitShareAdjust)
      const netProfitFinalTotal = Number(netProfitTotal) + Number(profitShareAdjust)
      state.form.netProfitFinalTotal = netProfitFinalTotal.toFixed(2)
    })

    const save = () => {
      saveProfitArchive(state.form.id, state.form).then(res => {
        if (res.code === 10000) {
          message.info(res.msg)
        }
      })
    }
    const doArchive = () => {
      archiveProfitArchive(state.form.id, state.form).then(res => {
        if (res.code === 10000) {
          message.info(res.msg)
        }
      })
    }

    return {
      ...toRefs(state),
      amountUppercase,
      formatNumber,
      save,
      doArchive,
      exportDetailExcel
    }
  }
}
</script>
<style lang="less" scoped>
.suspension-button {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px 16px;
}
.save-button {
  width: 100%;
  margin: 10px 16px;
  text-align: right;
}

.ant-input-affix-wrapper {
  font-size: 18px;
}
.numeric-input .ant-tooltip-inner {
  min-width: 32px;
  min-height: 37px;
}
.numeric-input .numeric-input-title {
  font-size: 14px;
}
.archiveTag {
  position: absolute;
  width: 200px;
  top: 5px;
  right: 10px;
}
.table {
  width: 100%;
  font-size: 14px;

  td,
  th {
    text-align: center;
  }
  td {
    position: relative;
    min-height: 20px;
    line-height: 20px;
    border: 1px solid #43bcff;
    max-width: 300px;
  }

  &-title {
    font-size: 22px;
    color: #0b94dd;
    padding-bottom: 24px !important;
  }

  .left-td {
    text-align: left;
  }
  .right-td {
    text-align: right;
  }

  .label-desc {
    font-weight: 500;
  }

  .label-td {
    font-size: 16px;
    font-weight: 600;
    color: #0b94dd;
    padding: 10px;
  }

  .value-td {
    padding: 0 10px;
  }

  .ant-form-item {
    margin: 0;
  }
  .ant-typography {
    margin: 0;
  }
}
</style>
