<template>
  <a-row type="flex" :gutter="16" style="flex-flow:unset;">
    <a-col flex="200px">
      <a-card>
        <template #title>
          <a-space>
            <a-button type="primary" small="small" @click="updateArichive">更新数据</a-button>
            <a-button type="link" small="small" @click="exportExcel">明细</a-button>
            <a-button type="link" small="small" @click="exportEscrowExcel">代管费</a-button>
          </a-space>
        </template>
        <a-tree @select="treeSelect" showLine blockNode>
          <a-tree-node v-for="(year,x) in treeList" :title="year.nodeName" :key="`${x}`">
            <a-tree-node v-for="(month,y) in year.children" :title="month.nodeName" :key="`${x}-${y}`">
              <a-tree-node v-for="(org,z) in month.children" :title="org.nodeName" :key="`${x}-${y}-${z}`">
              </a-tree-node>
            </a-tree-node>
          </a-tree-node>
        </a-tree>
      </a-card>
    </a-col>
    <a-col flex="auto">
      <Table ref="profitArchiveTable" v-show="tableVisible" :query="query" />
      <Form v-show="formVisible" :archiveId="archiveId" />
    </a-col>
  </a-row>
</template>
<script>
import { transProfitArchiveTree, updateProfitArchive } from '@/api/trans/finance/profit'
import { reactive, ref, toRefs } from 'vue'
import { onMounted } from '@vue/runtime-core'

import { message, Modal } from 'ant-design-vue'
import Form from './Form.vue'
import Table from './Table.vue'
export default {
  setup () {
    const profitArchiveTable = ref()
    const state = reactive({
      tableVisible: false,
      formVisible: false,
      treeList: [],
      archiveId: '',
      query: {}
    })
    onMounted(() => {
      transProfitArchiveTree({}).then(res => {
        if (res.code === 10000) {
          state.treeList = res.data
        }
      })
    })

    const treeSelect = key => {
      const indexs = key.length && key[0].split('-')
      switch (indexs.length) {
        case 1:
          state.query = { year: state.treeList[indexs[0]].nodeValue }
          state.tableVisible = true
          state.formVisible = false
          break
        case 2:
          state.query = { year: state.treeList[indexs[0]].nodeValue, month: state.treeList[indexs[0]].children[indexs[1]].nodeValue }
          state.tableVisible = true
          state.formVisible = false
          break
        case 3:
          state.archiveId = state.treeList[indexs[0]].children[indexs[1]].children[indexs[2]].nodeValue
          state.tableVisible = false
          state.formVisible = true
          break
        default:
          state.archiveId = ''
          state.query = {}
          state.tableVisible = false
          state.formVisible = false
          break
      }
    }

    const updateArichive = () => {
      updateProfitArchive({}).then(res => {
        if (res.code === 10000) {
          message.info('更新成功')
        }
      })
    }

    const exportExcel = () => {
      if (state.query.year || state.query.month) {
        profitArchiveTable.value.exportExcel()
      } else {
        Modal.warning({
          content: '请选择需要导出的年份或者月份'
        })
      }
    }
    const exportEscrowExcel = () => {
      if (state.query.year || state.query.month) {
        profitArchiveTable.value.exportEscrowExcel()
      } else {
        Modal.warning({
          content: '请选择需要导出的年份或者月份'
        })
      }
    }

    return {
      profitArchiveTable,
      ...toRefs(state),
      treeSelect,
      updateArichive,
      exportExcel,
      exportEscrowExcel
    }
  },
  components: {
    Form,
    Table

  }
}
</script>
